.InfoText {
  font-size: 0.875rem;
}

.small {
  font-size: 0.8rem;
}

.info {
  color: var(--color-28);
}

.success {
  color: var(--color-23);
}

.error {
  color: var(--color-20);
}
