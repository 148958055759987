.Navigation {
  position: sticky;
  top: 0;
  padding: 0 8px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, var(--color-24), var(--color-25));
  backdrop-filter: blur(18px);
  z-index: 9;
}

.Navigation a {
  min-height: 50px;
  display: flex;
  gap: 8px;
  font-weight: 400;
}

:global(.MuiTabs-scroller) {
  display: flex !important;
}

.Navigation :global(.MuiTabs-indicator) {
  height: 3px;
  transform: translateY(-0px);
}
