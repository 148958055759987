.Background {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: url("../../assets/bg-01.jpg");
  background-position: bottom 10% right 0%;
  background-size: cover;
  opacity: 0.14;
  animation: zoom 18s infinite;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 1200px) {
  .Background {
    background-position: bottom 10% right 10%;
  }
}

@media (max-width: 700px) {
  .Background {
    background-position: bottom 10% right 18%;
  }
}

@media (max-width: 380px) {
  .Background {
    background-position: bottom 10% right 10%;
  }
}

@media (max-aspect-ratio: 1/2) {
  .Background {
    background-position: bottom 10% right 20%;
  }
}

@media (max-aspect-ratio: 1/3) {
  .Background {
    background-position: bottom 10% right 25%;
  }
}

@media (min-aspect-ratio: 2/1) {
  .Background {
    /* background: red; */
    background-position: bottom 50% right 0%;
  }
}
