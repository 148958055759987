.Submit {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 8px;
}

.right {
  justify-content: flex-end;
}
