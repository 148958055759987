.LastKeyGiverDropsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.select {
  max-width: 250px;
}
