.MobileWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
}

@media (max-width: 580px) {
  .MobileWrapper {
    padding: 0px 15px;
  }
}
