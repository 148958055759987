.Header {
  position: relative;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-29);
}

.Header h1 {
  display: flex;
  font-family: var(--font-secondary);
  font-size: 50px;
  display: none;
}

.logo {
  font-size: 8px;
  font-weight: 800;
  color: var(--color-33);
  transform: scaleY(0.8);
}

@media (max-width: 700px) {
  .Header h1 {
    display: block;
  }

  .logo {
    display: none;
  }
}

@media (max-width: 630px) {
  .Header {
    justify-content: start;
  }
  .Header h1 {
    line-height: 1;
  }
}

@media (max-width: 480px) {
  .Header h1 {
    font-size: 40px;
  }

  .logo {
    font-size: 4px;
  }
}

@media (max-width: 400px) {
  .Header h1 {
    font-size: 30px;
  }

  .logo {
    font-size: 3px;
  }
}

.center {
  justify-content: center;
}
