.Submenu {
  position: sticky;
  top: 50px;
  padding: 0 8px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    var(--color-26-opacity-2),
    var(--color-26-opacity-1)
  );
  backdrop-filter: blur(18px);
  border-top: 1px solid var(--color-22);
  z-index: 9;
}

.level3 {
  top: 100px;
  background: linear-gradient(
    90deg,
    var(--color-30-opacity-2),
    var(--color-30-opacity-1)
  );
}

.Submenu a {
  min-height: 50px;
  display: flex;
  gap: 8px;
  font-weight: 400;
}

.Submenu :global(.MuiTabs-indicator) {
  height: 3px;
  transform: translateY(-1px);
}
