.Form {
  width: 100%;
}

.form {
  display: flex;
  gap: 8px;
}

.label {
  margin-bottom: 20px;
  font-size: 1.3em;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.form :global(.MuiInputBase-root.MuiAutocomplete-inputRoot) {
  padding-left: 14px;
}

:global(.MuiAutocomplete-paper) {
  background-color: #121212;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.12),
    rgba(255, 255, 255, 0.12)
  ) !important;
}

.InputAdornmentWrapper {
  display: flex;
  gap: 8px;
}

:global(.MuiInputBase-inputMultiline)::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

:global(.MuiInputBase-inputMultiline)::-webkit-scrollbar-track {
  background: linear-gradient(
    90deg,
    transparent,
    transparent 50%,
    var(--color-25) 50%,
    var(--color-25)
  );
}

:global(.MuiInputBase-inputMultiline)::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90deg,
    transparent,
    transparent 50%,
    var(--color-26) 50%,
    var(--color-26)
  );
  outline: none;
}

.form :global(.MuiFormHelperText-root) {
  font-size: 0.9rem;
  text-align: right;
}

.form :global(.Mui-error .MuiOutlinedInput-notchedOutline) {
  border-color: #f44336aa;
}
