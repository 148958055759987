.KeysList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.actions {
  display: flex;
  gap: 20px;
  /* justify-content: end; */
}
