.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.Loader.center {
  width: 100%;
}

.spinner.small {
  border: 8px solid var(--color-22);
  border-top: 8px solid var(--color-21);
  width: 40px;
  height: 40px;
}

.spinner {
  border: 14px solid var(--color-22);
  border-radius: 50%;
  border-top: 14px solid var(--color-21);
  width: 80px;
  height: 80px;
  animation: loader 1s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
