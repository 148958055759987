.User {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.User p {
  font-weight: 400;
  margin-right: 10px;
}

@media (max-width: 480px) {
  .User p {
    font-size: 0.8em;
  }
}
