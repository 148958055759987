.KeyGiverDropsStatsView {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}

.select {
  max-width: 250px;
}
