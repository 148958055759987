.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(135deg, var(--color-22), var(--color-1));
  z-index: -1;
}

.section {
  padding: 60px 20px 40px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.section > * {
  position: relative;
  max-width: 800px;
}

.toast :global(.Toastify__toast-theme--dark) {
  background: linear-gradient(90deg, var(--color-24), var(--color-25));
  backdrop-filter: blur(4px);
}

@media (max-width: 580px) {
  .section {
    padding: 60px 0px 40px 0px;
  }
}
