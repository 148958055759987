.AddItems {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.info {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textfield {
  margin-bottom: 10px;
  width: 100%;
}

.textfield textarea {
  color: var(--color-10);
}

.Tables {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form {
  width: 100%;
}
