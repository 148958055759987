.toggleButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 27px;
  color: var(--color-10);
}

.toggleButton > p {
  padding-left: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
}
