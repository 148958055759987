.List {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.title {
  padding: 15px;
  font-size: 1.14em;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--color-10);
  border-bottom: 1px solid var(--color-21);
  z-index: 1;
  background-color: var(--color-2);
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.ul {
  list-style: none;
}

.li {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 0.875em;
  border-bottom: 1px solid var(--color-10-opacity);
}

.li:nth-child(even) {
  background-color: #ffffff0c;
}

.liPaddings {
  padding: 15px;
}

.cursorPointer {
  cursor: pointer;
}

.noData {
  width: 100%;
  justify-content: center;
  background-color: #ffffff0c;
  border-bottom: 1px solid var(--color-21);
}
