.EditableKeyGiverDropsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.actions {
  display: flex;
  /* align-items: center; */
  gap: 20px;
}

.name {
  color: var(--color-23);
  font-weight: 600;
}

.short {
  color: var(--color-10);
}

.locationId {
  color: var(--color-28);
  font-weight: 600;
}
